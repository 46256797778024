import { call, put, take, takeEvery, select } from 'redux-saga/effects'
import { fetchDesignations, fetchEmployees, fetchEvaluations, addEvaluation as PostEvaluation } from '../../api/employee'
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
import { request, failed, success } from '../../views/Employees/EmployeeSlice'
import { request as DesignationRequest, success as DesignationSuccess } from '../../views/Employees/DesignationSlice'
import { add as EvaluationAdd, request as EvaluationRequest, success as EvaluationSuccess, failed as AddFailed } from '../../views/Employees/EvaluationSlice'
const pendingEvaluation = state => state.evaluation.pending;
export const toPc = item => {
    if (item == 1) {
        return 0;
    }
    if (item == 2) {
        return 50;
    }
    if (item == 3) {
        return 75;
    }
    if (item == 4) {
        return 100;
    }
}
function* getEvaluations() {
    const evaluation = yield call(fetchEvaluations);
    evaluation.forEach(item => {
        var total = toPc(+item.on_time) + toPc(+item.quality) + toPc(+item.team_work) + toPc(+item.extra_mile);
        total = total / 4;
        item.effort_ratings = total.toFixed(1);
        item.effort_ratings_after_weighage = (+total * 0.4).toFixed(1);
        var okr = +item.okr * 0.6;
        var performance = +okr + +item.effort_ratings_after_weighage;
        item.total_performance = performance;
        if (item.total_performance > 70) {
            item.total_bonus = (item.total_performance - 70) * 1.666666667;
        } else {
            item.total_bonus = 0;
        }
    })
    evaluation.sort((a, b) => b.total_performance - a.total_performance);
    evaluation.forEach((item, index) => {
        item.ranking = index + 1;
    })
    yield put({ type: EvaluationSuccess.toString(), payload: evaluation });
}

export function* addEvaluation() {
    const pending = yield select(pendingEvaluation);
    try {
        const result = yield call(PostEvaluation, pending);
        yield call(getEvaluations);
    } catch (error) {
        yield put({ type: AddFailed.toString() });
    }
}
export function* addEvaluationSaga() {
    yield takeEvery(EvaluationAdd.toString(), addEvaluation);
}

export function* evaluationSaga() {
    yield take(EvaluationRequest.toString());
    yield call(getEvaluations);
}

function* employeeSaga() {

    yield take(request);
    const users = yield call(fetchEmployees);
    if (users && users.length > 0) {
        yield put({ type: success.toString(), users });
    } else {
        yield put(failed);
    }

}
export function* designationSaga() {
    yield take(DesignationRequest.toString());
    const data = yield call(fetchDesignations);
    if (data.designations && data.designations.length > 0) {
        yield put({ type: DesignationSuccess.toString(), payload: { designations: data.designations, roles: data.roles } });
    }
}
export default employeeSaga;