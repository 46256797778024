import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    data : [],
    loading : false
};

const EmployeeSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        request(state) {
            state.loading = true;
        },
        failed(state) {
            state.loading = false;
        },
        success(state, action : any) {
            state.data = [...action.users];
        }
    },
});

export const { request, failed, success } = EmployeeSlice.actions;

export default EmployeeSlice.reducer;
