import axios from 'axios';
export const fetchEmployees = async () => {
    return new Promise((resolve) => {
        return fetch(`${process.env.REACT_APP_API_URL}/employees`).then(function (response) {
            response.json().then(function (result) {
                resolve(result);
            }).catch(function () {
                resolve([]);
            })
        }).catch(function () {
            resolve([]);
        })
    })
}
export const fetchDesignations = async () => {
    return new Promise((resolve) => {
        return fetch(`${process.env.REACT_APP_API_URL}/employees/designations`).then(function (response) {
            response.json().then(function (result) {
                resolve(result);
            }).catch(function () {
                resolve([]);
            })
        }).catch(function () {
            resolve([]);
        })
    })
}
export const fetchEvaluations = async () => {
    return new Promise((resolve) => {
        return fetch(`${process.env.REACT_APP_API_URL}/employees/evaluation`).then(function (response) {
            response.json().then(function (result) {
                resolve(result);
            }).catch(function () {
                resolve([]);
            })
        }).catch(function () {
            resolve([]);
        })
    })
}
export const addEvaluation = async (evaluation) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/ess/add`, { evaluation: evaluation }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });

}