import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  failed: false,
  loader: false,
  compititorLsiList: [],
  compititorLsiListLoading: false,
  updateWorkflowLoading: false,
  createWorkflowFormData: {},
  updateAgentLoading: false,
  createAgentFormData: {},
  agents: {},
  new_history: {},
  history: {
    data: null,
    sidebarLoader: false,
    title: "",
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  stats: {
    data: null,
    sidebarLoader: false,
    title: "",
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  bulkList:{},
  journey:{},
  taskInputData:{},
  onpageseo: {
    data: null,
    sidebarLoader: false,
    title: "",
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  onPageSeoDetails:{}
};

const ToolSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    request(state) {
      state.loading = true;
    },
    success(state, action) {
      state.trendings = action.payload;
      state.loading = false;
    },

    setCompititorLsiList(state, action) {
      state.compititorLsiList = action.payload;
    },
    setContentSelectedLsiList(state, action) {
      state.compititorLsiList = action.payload;
    },
    setCompititorLsiListLoading(state, action) {
      state.compititorLsiListLoading = action.payload;
    },
    setUpdateWorkflowLoading(state, action) {
      state.updateWorkflowLoading = action.payload;
    },
    setcreateWorkflowFormData(state, action) {
      state.createWorkflowFormData = action.payload;
    },
    setUpdateAgentLoading(state, action) {
      state.updateAgentLoading = action.payload;
    },
    setcreateAgentFormData(state, action) {
      state.createAgentFormData = action.payload;
    },
    setHistory(state, action) {
      // state.history.data = action.payload;
      state.history = action.payload;
    },
    setNewHistory(state, action) {
      state.new_history = action.payload;
    },
    setStats(state, action) {
      state.stats = action.payload;
    },
    setBulkList(state, action) {
      state.bulkList= action.payload;
    },
    setAgents(state, action) {
      state.agents = action.payload;
    },
    setJourney(state, action) {
      state.journey = action.payload;
    },
    setTaskInputData(state, action) {
      state.taskInputData = action.payload;
    },
    setOnPageSeo(state, action) {
      state.onpageseo = action.payload;
    },
    setOnPageSeoDetails(state, action) {
      state.onPageSeoDetails = action.payload;
    },
    setLoader(state, action){
      state.loader = action.payload;
    }
  },
});

export const {
  request,
  success,
  setCompititorLsiList,
  setContentSelectedLsiList,
  setCompititorLsiListLoading,
  setUpdateWorkflowLoading,
  setcreateWorkflowFormData,
  setUpdateAgentLoading,
  setcreateAgentFormData,
  setHistory,
  setStats,
  setAgents,
  setBulkList,
  setJourney,
  setTaskInputData,
  setOnPageSeo,
  setNewHistory,
  setLoader,
  setOnPageSeoDetails
} = ToolSlice.actions;

export default ToolSlice.reducer;
